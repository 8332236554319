import React from 'react'

import HomePage from 'components/HomePage'

const Home: React.FC = () => {
  return (
    <>
      <HomePage />
    </>
  )
}

export default Home
